<template>
  <v-form lazy-validation ref="passwordForm" @submit.prevent="resetPassword">
    <div class="wa__forgot__form mt-12 px-3">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field
            class="pt-0 mt-0"
            :label="$_t('password')"
            :type="showPass ? 'text' : 'password'"
            v-model="form.password"
            @click:append="showPass = !showPass"
            :append-icon="showPass ? 'WMi-eye-off' : 'WMi-eye'"
            color="SonicSilver"
            :rules="password"
            hint="password must have a 1 Number and 1 Capital letter"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :type="showPassConfig ? 'text' : 'password'"
            @click:append="showPassConfig = !showPassConfig"
            :append-icon="showPassConfig ? 'WMi-eye-off' : 'WMi-eye'"
            class="pt-0 mt-0 no-error-msg"
            :label="$_t('password')"
            v-model="form.password_confirmation"
            :rules="password"
            color="SonicSilver"
            hint="password must have a 1 Number and 1 Capital letter"
            dense
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="wa__tile--link pl-0">
          <block-button
            height="35"
            class="btn-return float-left"
            :text="$_t('returnToLogin')"
            text-mode="text"
            icon="WMi-left-open"
            @click.native="$emit('goBack')"
          ></block-button>
        </v-col>
        <v-col cols="6" class="wa__tile--link">
          <block-button
            height="35"
            class="btn-send site__button width-auto bg-transparent"
            :text="$_t('reset')"
            :loading="changePassLoading"
            type="submit"
          ></block-button>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import AuthRepository from "../abstraction/repository/authRepository";
let authRepository = new AuthRepository();
import {password} from "@/mixins/validate"
export default {
  props: {
    form: {
      default: () => ({}),
    },
  },
  data() {
    return {
      changePassLoading: false,
      showPassConfig: false,
      showPass: false,
    };
  },
  methods: {
    async resetPassword() {
      try {
        this.changePassLoading = true;
        if (!this.$refs.passwordForm.validate()) return;
        await authRepository.resetPassword(this.form);
        this.$router.push({
          name: "dashboard",
          params: { locale: this.$_getlocaleParam() },
        });
      } catch (e) {
        return e;
      } finally {
        this.changePassLoading = false;
      }
    },
  },
  computed: {
    password
  },
  mounted() {
      this.$refs.passwordForm.resetValidation();
  },
};
</script>

<style scoped>
.wa__forgot__form {
  width: 25%;
  margin: 0 auto;
}
.wa__tile--link a {
  text-decoration: none;
  display: block;
  color: initial;
}
.btn-return {
  font-size: 15px !important;
  letter-spacing: 3px !important;
}
.btn-send {
  font-size: 14px !important;
  padding: 0 60px !important;
  letter-spacing: 3px !important;
  float: right;
}
@media screen and (max-width: 1264px) {
  .wa__forgot__form {
    width: 80%;
  }
}
@media screen and (max-width: 960px) {
  .wa__forgot__form {
    width: 90%;
  }
  .btn-send {
    width: 80% !important;
  }
}
@media screen and (max-width: 600px) {
  .btn-return {
    font-size: 10px !important;
    letter-spacing: 2px !important;
  }
  .btn-send {
    font-size: 12px !important;
    padding: 0 60px !important;
    width: -webkit-fill-available;
    width: 100% !important;
    letter-spacing: 2px !important;
  }
}
</style>
